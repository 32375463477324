.techs {
  margin: 0;
  padding: 99px 70px 100px 70px;
  background-color: #f5f5f5;
}

.techs__text {
  margin: 26px auto 0;
  max-width: 460px;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.56px;
}

.techs__title {
  padding: 0;
  margin: 114px 0 0 0;
  text-align: center;
  letter-spacing: -2px;
  font-size: 50px;
  font-style: normal;
  font-weight: 400;
  line-height: 58px;
}

.techs__list {
  padding: 0;
  margin: 100px auto 0;
  list-style: none;
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(auto-fit, 90px);
  grid-gap: 10px;
}

.techs__list-element {
  margin: 0;
  padding: 21px 0 22px;
  box-sizing: border-box;
  text-align: center;
  background: #e8e8e8;
  font-size: 14px;
  font-style: normal;
  line-height: normal;
  font-weight: 400;
  letter-spacing: -0.56px;
  border-radius: 10px;
}

@media (max-width: 839px) {
  .techs {
    padding: 90px 50px;
  }

  .techs__title {
    margin: 104px 0 0 0;
  }

  .techs__text {
    margin: 22px auto 0;
    font-size: 12px;
    letter-spacing: -0.48px;
    line-height: 18px;
  }

  .techs__list {
    margin: 83px auto 0;
    grid-template-columns: repeat(auto-fit, 84px);
  }

  .techs__list-element {
    font-size: 12px;
    letter-spacing: -0.48px;
  }
}

@media (max-width: 609px) {
  .techs {
    padding: 71px 18px 71px;
  }

  .techs__title {
    margin: 80px 0 0 0;
    letter-spacing: -1.2px;
    font-size: 30px;
  }

  .techs__list-element {
    padding: 20.5px 0 22px;
  }

  .techs__text {
    margin: 11px auto 0;
    font-size: 11px;
    letter-spacing: -0.44px;
    line-height: 16px;
  }

  .techs__list {
    margin: 50px 10px 0;
  }
}
