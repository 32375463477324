.footer {
  margin: 0;
  padding: 70px 70px 20px;
}

.footer__info {
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: space-between;
}

.footer__link {
  color: #000;
  text-decoration: none;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.footer__text {
  margin: 0 0 41px;
  padding: 0;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  color: #a0a0a0;
  line-height: normal;
  text-align: center;
  position: relative;
}

.footer__text::after {
  margin: 0;
  padding: 0;
  content: ' ';
  top: 35px;
  left: 0px;
  width: 100%;
  height: 1px;
  background: #e8e8e8;
  position: absolute;
}

.footer__copyright {
  margin: 0;
  padding: 0;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.footer__list-element {
  padding: 0;
  margin: 0 0 0 20px;
}

.footer__list {
  margin: 0;
  padding: 0;
  display: flex;
  list-style: none;
}

@media (max-width: 839px) {
  .footer {
    padding: 70px 30px 20px;
  }
}

@media (max-width: 609px) {
  .footer {
    padding: 78px 10px 20px;
  }

  .footer__text {
    margin: 0 0 48px;
    font-size: 12px;
  }

  .footer__info {
    flex-direction: column-reverse;
    align-items: center;
  }

  .footer__link {
    font-size: 12px;
  }

  .footer__list {
    flex-direction: column;
    align-items: center;
  }

  .footer__list-element {
    margin: 0 0 8px 0;
  }

  .footer__copyright {
    margin: 21px 0 0 0;
    color: #a0a0a0;
    font-size: 12px;
  }
}
