.portfolio {
  padding: 51px 70px 125px;
  margin: 0;
}

.portfolio__list {
  padding: 0;
  margin: 0;
  list-style: none;
}

.portfolio__list-element {
  padding: 0;
  margin: 0;
}

.portfolio__link {
  margin: 0;
  padding: 30px 0 0;
  display: flex;
  justify-content: space-between;
  text-decoration: none;
  color: #000;
  position: relative;
}

.portfolio__link::after {
  margin: 0;
  padding: 0;
  content: ' ';
  background: #e8e8e8;
  width: 100%;
  height: 1px;
  top: 97px;
  left: 0px;
  position: absolute;
}

.portfolio__link-pointer {
  margin: 0;
  padding: 0;
  font-size: 30px;
  font-style: normal;
  line-height: 60px;
  font-weight: 400;
  letter-spacing: -1.2px;
}

.portfolio__link-name {
  margin: 0;
  padding: 0;
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: 50px;
  letter-spacing: -1.2px;
}

.portfolio__title {
  margin: 0;
  padding: 0 0 20px 0;
  color: #a0a0a0;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

@media (max-width: 839px) {
  .portfolio {
    padding: 40px 50px 90px;
  }

  .portfolio__link-name {
    font-size: 28px;
  }

  .portfolio__link-pointer {
    font-size: 28px;
  }
}

@media (max-width: 609px) {
  .portfolio {
    padding: 20px 14px 70px;
  }

  .portfolio__title {
    padding: 0 0 1px 0;
    font-size: 14px;
  }

  .portfolio__link {
    padding: 39.5px 0 0;
  }

  .portfolio__link-name {
    font-size: 18px;
    line-height: 28px;
    letter-spacing: -0.72px;
  }

  .portfolio__link-pointer {
    font-size: 18px;
    line-height: 28px;
    letter-spacing: -0.72px;
  }

  .portfolio__link::after {
    top: 86px;
  }
}
