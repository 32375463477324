.authInput {
  position: relative;
  width: 100%;
}

.authInput__text { 
  font-size: 10px;
  font-style: normal;
  line-height: normal;
  font-weight: 400;
  color: #a0a0a0;
}

.authInput__error {
  margin: 0;
  padding: 0;
  top: 57px;
  left: 0px;
  color: #ee3465;
  font-size: 10px;
  font-weight: 400;
  font-style: normal;
  line-height: normal;
}

.authInput__input {
  padding: 4px 0 10px;
  margin: 0 0 8px;
  width: 100%;
  font-size: 14px;
  font-style: normal;
  line-height: normal;
  font-weight: 500;
  outline: none;
  border: none;
  color: #000;
  background-color: transparent;
  border-bottom: 1px solid #e8e8e8;
}

.authInput__input:focus {
  border-bottom: 1px solid #3456f3;
}
