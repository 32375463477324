.aboutMe {
  margin: 0;
  padding: 110px 70px 50px;
}

.aboutMe__link {
  margin: 0;
  padding: 0;
  text-decoration: none;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: #000;
}

.aboutMe__photo {
  margin: 0;
  padding: 0;
  width: 269px;
  height: 327px;
  display: inline-block;
  object-fit: cover;
  border-radius: 10px;
}

.aboutMe__name {
  margin: 0;
  padding: 0;
  font-size: 50px;
  letter-spacing: -2px;
  font-style: normal;
  font-weight: 400;
  line-height: 58px;
}

.aboutMe__cards {
  margin: 90px 0 0 0;
  padding: 0;
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
}

.aboutMe__myRole {
  margin: 18px 0 26px;
  padding: 0;
  font-feature-settings: 'clig' off, 'liga' off;
  max-width: 598px;
  line-height: 20px;
  font-weight: 500;
  font-size: 18px;
  font-style: normal;
}

.aboutMe__info {
  margin: 0 0 99px;
  padding: 0;
  font-feature-settings: 'clig' off, 'liga' off;
  max-width: 598px;
  line-height: 22px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}

@media (max-width: 839px) {
  .aboutMe {
    padding: 90px 50px 50px;
  }

  .aboutMe__myRole {
    margin: 16px 0 20px;
    max-width: 347px;
    font-size: 12px;
    line-height: 18px;
  }

  .aboutMe__photo {
    height: 308px;
    width: 256px;
  }

  .aboutMe__name {
    font-size: 40px;
    line-height: 40px;
    letter-spacing: -1.6px;
  }

  .aboutMe__info {
    max-width: 365px;
    margin: 0 0 87px;
    font-size: 12px;
    line-height: 18px;
  }
}

@media (max-width: 609px) {
  .aboutMe {
    padding: 69px 14px 50px;
  }

  .aboutMe__myRole {
    margin: 20px 0;
    line-height: 16px;
    font-size: 11px;
  }

  .aboutMe__cards {
    margin: 90px 0 0 0;
    flex-direction: column;
  }

  .aboutMe__photo {
    margin: 0 auto 40px;
    height: 352px;
    width: 292px;
    border-radius: 10px;
    display: inline-block;
    object-fit: cover;
  }

  .aboutMe__info {
    margin: 0 0 38px;
    line-height: 16px;
    font-size: 11px;
    max-width: 100%;
  }

  .aboutMe__name {
    font-size: 30px;
    line-height: normal;
    letter-spacing: -1.2px;
  }
}

@media (max-width: 326px) {
  .aboutMe__photo {
    width: 285px;
  }
}
