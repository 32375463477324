.navigate {
  margin: 0;
  padding: 0;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: flex-end;
  visibility: hidden;
  transition: 0.3s ease;
  opacity: 0;
}

.navigate_opened {
  visibility: visible;
  opacity: 1;
}

.navigate:hover {
  cursor: pointer;
}

.navigate__container {
  padding: 160px 90px 90px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  min-width: 520px;
  height: 100%;
  background: #ffffff;
  color: #000000;
  position: relative;
  box-sizing: border-box;
}

.navigate__container:hover {
  cursor: default;
}

.navigate__links {
  margin: 0;
  padding: 0;
  text-align: center;
  list-style: none;
}

.navigate__links-element {
  padding: 0;
  margin: 0 0 29px 0;
  text-decoration: none;
}

.navigate__link {
  padding: 0;
  margin: 0;
  color: #000;
  font-size: 18px;
  font-weight: 500;
  font-style: normal;
  line-height: normal;
  position: relative;
  text-decoration: none;
  font-feature-settings: 'clig' off, 'liga' off;
}

.navigate__link-active::after {
  margin: 0;
  padding: 0;
  content: ' ';
  background: black;
  width: 100%;
  height: 2px;
  left: 0px;
  top: 26px;
  position: absolute;
}

.navigate__buttonProfile {
  margin: 0;
  padding: 6px 11px;
  color: #000;
  font-family: 'Inter', 'Arial', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  text-decoration: none;
  display: flex;
  align-items: center;
  border: 2px solid rgba(0, 0, 0, 0.1);
  border-radius: 50px;
}

.navigate__buttonProfile-img {
  padding: 0;
  margin: 0 7px 0 0;
  width: 12px;
  height: 14px;
  background-image: url(../../images/profile.svg);
  background-size: contain;
  background-repeat: no-repeat;
}

.navigate__buttonClose {
  padding: 0;
  width: 32px;
  height: 32px;
  background-image: url(../../images/icon-close.svg);
  background-repeat: no-repeat;
  background-color: transparent;
  background-size: cover;
  position: absolute;
  border: none;
  top: 22px;
  right: 22px;
}

.navigate__buttonClose:hover {
  cursor: pointer;
  opacity: 0.8;
  transition: all 0.7s ease;
}

@media (max-width: 609px) {
  .navigate__container {
    padding: 145px 10px 98px;
    width: 100%;
    min-width: 320px;
  }

  .navigate__links-element {
    margin: 0 0 25px 0;
  }

  .navigate__link:active::after {
    top: 29px;
  }

  .navigate__buttonClose {
    top: 13px;
    right: 13px;
  }
}
