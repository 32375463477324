.promo {
  margin: 0;
  padding: 184px 0px 224px;
  background-color: #dddee3;
  background-image: url(../../images/landing-logo.svg);

  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: 82px;
}

.promo__title {
  margin: 0 auto;
  padding: 0;
  width: 730px;
  font-size: 50px;
  font-weight: 400;
  line-height: 58px;
  letter-spacing: -2px;
  font-style: normal;
  text-align: center;
}

@media (max-width: 839px) {
  .promo {
    padding: 326px 0px 342px;
    background-position-y: 224px;
  }
}

@media (max-width: 747px) {
  .promo__title {
    width: 95%;
  }
}

@media (max-width: 609px) {
  .promo {
    padding: 208px 0px 245px;
    background-position-y: 143px;
    background-size: 247px;
  }
  .promo__title {
    font-size: 31px;
    letter-spacing: -1.24px;
    line-height: 39px;
  }
}
