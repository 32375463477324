.aboutProject {
  padding: 111px 70px;
}

.aboutProject__description {
  margin: 95px auto;
  padding: 0;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(550px, 1fr));
  grid-row-gap: 26px;
  grid-column-gap: 40px;
}

.aboutProject__description-info {
  margin: 0;
  padding: 0;
  font-size: 14px;
  font-style: normal;
  line-height: 20px;
  font-weight: 400;
}

.aboutProject__description-title {
  margin: 0;
  padding: 0;
  font-size: 20px;
  font-style: normal;
  line-height: normal;
  font-weight: 400;
  letter-spacing: -0.8px;
}

.aboutProject__time-info {
  margin: 0;
  padding: 0;
  color: #a0a0a0;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  font-style: normal;
  justify-content: center;
}

.aboutProject__time {
  margin: 0;
  padding: 0;
  display: grid;
  grid-row-gap: 14px;
  grid-template-columns: 20% 80%;
}

.aboutProject__time-weeks {
  margin: 16px 0 0 0;
  padding: 0;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.aboutProject__time-weeks:first-of-type {
  background-color: #3456f3;
  color: #fff;
}

.aboutProject__time-weeks:nth-of-type(2) {
  background-color: #f2f2f2;
}

@media (max-width: 1278px) {
  .aboutProject__description {
    grid-row-gap: 22px;
    grid-column-gap: 30px;
    grid-template-columns: repeat(auto-fit, minmax(450px, 1fr));
  }
}

@media (max-width: 1079px) {
  .aboutProject__description {
    grid-row-gap: 22px;
    grid-column-gap: 30px;
    grid-template-columns: repeat(auto-fit, minmax(330px, 1fr));
  }
}

@media (max-width: 839px) {
  .aboutProject {
    padding: 90px 50px;
  }

  .aboutProject__description-info {
    font-size: 12px;
    line-height: 18px;
  }

  .aboutProject__description {
    grid-template-columns: repeat(auto-fit, minmax(319px, 1fr));
    grid-row-gap: 22px;
    grid-column-gap: 30px;
  }

  .aboutProject__time-weeks {
    margin: 0;
  }

  .aboutProject__time {
    grid-template-columns: 21% 79%;
    grid-row-gap: 14px;
  }
}

@media (max-width: 769px) {
  .aboutProject__description {
    grid-row-gap: 22px;
    grid-column-gap: 30px;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  }
}

@media (max-width: 730px) {
  .aboutProject__description {
    grid-template-areas:
      'title-first'
      'text-first'
      'title-second'
      'text-second';
    gap: 18px;
  }

  .grid-text-one {
    margin-bottom: 40px;
    grid-area: text-first;
  }

  .grid-text-two {
    grid-area: text-second;
  }

  .grid-title-one {
    grid-area: title-first;
  }

  .grid-title-two {
    grid-area: title-second;
  }
}

@media (max-width: 609px) {
  .aboutProject {
    padding: 70px 18px;
  }

  .aboutProject__description-title {
    font-size: 18px;
    letter-spacing: -0.72px;
  }

  .aboutProject__description-info {
    font-size: 11px;
    line-height: 16px;
  }

  .aboutProject__description {
    margin: 88px auto 60px;
  }

  .aboutProject__time {
    grid-row-gap: 10px;
    grid-template-columns: 36% 64%;
  }

  .aboutProject__time-info {
    line-height: normal;
    font-size: 11px;
  }

  .aboutProject__time-weeks {
    font-size: 11px;
  }

  .aboutProject__description {
    gap: 17px;
  }
}

@media (max-width: 430px) {
  .aboutProject__description {
    margin: 89px auto 58px;
    grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
  }
}
