.authForm {
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
}

.authForm__box {
  margin: auto 0 0 0;
  padding: 0;
  width: 100%;
  height: 81px;
  display: flex;
  flex-direction: column;
  justify-content: end;
}

.authForm__button {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 45px;
  border-radius: 3px;
  background: #3456f3;
  border: none;
  color: white;
  font-size: 14px;
  font-style: normal;
  line-height: normal;
  font-weight: 500;
}

.authForm__button-disabled {
  background: #e8e8e8;
}

.authForm__apiMessage {
  padding: 0;
  margin: 0 auto 10px;
  color: #ee3465;
  font-size: 11px;
  font-style: normal;
  line-height: normal;
  font-weight: 400;
}


@media (max-width: 615px) {
  .authForm__button {
    font-size: 12px;
  }

  .authForm {
    width: 100%;
  }
}
