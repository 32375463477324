.card {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  width: 270px;
  height: 218px;
  overflow: hidden;
  background: #fff;
  box-shadow: 0px 16px 25px 0px #0000000d;
  border-radius: 4%;
}

.card__link {
  margin: 0;
  padding: 0;
  display: contents;
  text-decoration: none;
}

.card__box {
  display: flex;
  justify-content: space-between;
}

.card__duration {
  margin: 0;
  padding: 0;
  color: #a0a0a0;
  font-size: 11px;
  font-weight: 400;
  font-style: normal;
  line-height: normal;
}


.card__images {
  object-fit: cover;
  width: 270px;
  height: 151px;
}

.card__info {
  padding: 0;
  margin: 10px 12px 14px;
  max-width: 191px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.card__name {
  padding: 0;
  margin: 2px 0 10px 0;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.card__button {
  padding: 0;
  margin: 5px;
  width: 30px;
  height: 30px;
  border: none;
  background-position: 50%;
  background-color: transparent;
  background-repeat: no-repeat;
}

.card__deleteButton {
  margin-top: 3px;
  background-image: url(../../images/icon-delete.svg);
}

.card__likeButton {
  background-image: url(../../images/icon-invisible.svg);
}

.card__likeButton_active {
  background-image: url(../../images/icon-visible.svg);
}

@media (max-width: 839px) {
  .card {
    width: 339px;
    height: 257px;
  }

  .card__images {
    width: 339px;
    height: 190px;
  }
}

@media (max-width: 609px) {
  .card {
    width: 300px;
    height: 235px;
  }

  .card__button {
    margin: 5px;
    width: 32px;
    height: 32px;
  }
  
  .card__images {
    width: 300px;
    height: 168px;
  }

  .card__info {
    margin: 12px 12px 14px;
    max-width: 250px;
  }
}
