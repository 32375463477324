.profile {
  margin: 0;
  padding: 74px 0 70px;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: center;
}

.profile__form {
  padding: 0;
  margin: 116px 0 0 0;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: center;
}

.profile__input {
  margin: 0;
  padding: 7px 0 7px 10.5px;
  text-align: right;
  width: 350px;
  font-size: 11px;
  font-weight: 400;
  font-style: normal;
  line-height: normal;
  outline: none;
  border: none;
  background-color: transparent;
}

.profile__input-name {
  margin: 0;
  padding: 0;
  font-size: 11px;
  font-style: normal;
  line-height: normal;
  font-weight: 500;
}

.profile__title {
  margin: 0;
  padding: 0;
  font-size: 24px;
  font-weight: 500;
  font-style: normal;
  line-height: normal;
}

.profile__container {
  margin: 0 0 21px 0;
  display: flex;
  width: 410px;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.profile__container:first-of-type::after {
  margin: 0;
  padding: 0;
  content: ' ';
  width: 100%;
  height: 1px;
  top: 37px;
  left: 0px;
  background: #e8e8e8;
  position: absolute;
}

.profile__panel {
  margin: 0;
  padding: 0;
  display: flex;
  gap: 13px;
  flex-direction: column;
}

.profile__panel-button {
  font-size: 13px;
  font-style: normal;
  line-height: normal;
  border: none;
  background-color: transparent;
}

.profile__panel-buttonEdit {
  font-weight: 400;
}

.profile__panel-buttonExit {
  font-weight: 500;
  color: #ee3465;
}

.profile__button-save {
  width: 100%;
  height: 45px;
  border-radius: 3px;
  background: #3456f3;
  margin: auto 0 0 0;
  padding: 0;
  border: none;
  color: white;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.profile__button-save-disabled {
  background: #e8e8e8;
}

.profile__boxButtonSave {
  width: 100%;
  height: 122px;
  display: flex;
  flex-direction: column;
  justify-content: end;
  margin: auto 0 0 0;
  padding: 0;
}

.profile__input-error {
  margin: 0;
  padding: 0;
  top: 24px;
  left: 0px;
  position: absolute;
  color: #ee3465;
  font-size: 10px;
  font-weight: 400;
  font-style: normal;
  line-height: normal;
  z-index: 2;
}

.profile__apiMessage {
  padding: 0;
  margin: 0 auto 10px;
  font-size: 11px;
  font-style: normal;
  line-height: normal;
  font-weight: 400;
  color: #ee3465;
}

.profile__apiMessage_good {
  color: #4ab041;
  font-size: 14px;
}

@media (max-width: 839px) {
  .profile {
    padding: 237px 0 92px;
  }

  .profile__panel {
    flex-grow: 1;
  }

  .profile__form {
    margin: 94px 0 0 0;
  }

}

@media (max-width: 609px) {
  .profile {
    padding: 71px 0 91px;
  }

  .profile__form {
    margin: 79px 0 0 0;
  }

  .profile__container {
    width: 261px;
  }

  .profile__input {
    width: 200px;
  }

  .profile__panel {
    flex-grow: 0;
  }

  .profile__input-error {
    top: 26px;
  }

  .profile__panel-button {
    font-size: 12px;
  }
}
