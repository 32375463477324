.searchForm {
  margin: 0;
  padding: 70px 70px 80px;
}

input[type='text']::placeholder {   
  text-indent: 45px;
  color: #A0A0A0;
}

.searchForm__container {
  padding: 19px;
  display: flex;
  justify-content: space-between;
  border-radius: 9px;
  box-shadow: 0px 3px 14px 0px rgba(0, 0, 0, 0.08);
  position: relative;
  background: #fff;
}

.searchForm__container::after {
  margin: 0;
  padding: 0;
  content: ' ';
  background: #e8e8e8;
  width: 100%;
  height: 1px;
  top: 141px;
  left: 0px;
  position: absolute;
}

.searchForm__input {
  outline-color: #3456f3;
  width: 100%;
  border: none;
}

.searchForm__input::placeholder {
  background: url(../../images/icon-search.svg);
  background-position: left;
  background-repeat: no-repeat;
}

.searchForm__box {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-right: 14px;
  border-right: 1px solid #cbcbcb;
}

.searchForm__button {
  margin: 0 5px;
  width: 35px;
  height: 35px;
  border: none;
  background-color: transparent;
  background-image: url(../../images/icon-find.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.searchForm__filter-text {
  padding: 0;
  margin: 0 0 0 14px;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}

.searchForm__filter {
  margin: 0;
  padding: 0 10px 0 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 839px) {
  .searchForm {
    padding: 80px 30px 121px;
  }

  .searchForm__container::after {
    top: 182px;
  }

  .searchForm__filter {
    padding: 0 0px 0 21px;
  }
}

@media (max-width: 609px) {
  .searchForm {
    padding: 80px 10px 80px;
  }

  input[type='text']::placeholder {   
    font-size: 14px;
    text-indent: 1px;
  }

  .searchForm__input::placeholder {
    background: none;
  }

  .searchForm__container {
    padding: 0;
    flex-direction: column;
    box-shadow: none;
    border-radius: 0;
  }

  .searchForm__box {
    border: 0;
    margin-bottom: 45px;
    border-radius: 9px;
    padding: 18px;
    position: relative;
    box-shadow: 0px 3px 14px 0px #00000014;
    box-sizing: border-box;
  }

  .searchForm__button {
    padding: 0 15px;
  }

  .searchForm__filter {
    padding: 0;
  }

  .searchForm__filter-text {
    font-size: 11px;
  }
}
