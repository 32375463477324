.notFound {
  padding-top: 247px;
  padding-bottom: 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;
  min-height: 100dvh;
  flex-grow: 1;
}

.notFound__title {
  margin: 0;
  font-size: 140px;
  font-weight: 400;
  line-height: normal;
}

.notFound__button {
  margin: 185px 0 60px 0;
  padding: 0;
  color: #3456f3;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  border: none;
  background-color: transparent;
}

.notFound__description {
  margin: 5px 0 0 0;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
}

@media (max-width: 845px) {
  .notFound {
    padding-top: 408px;
    padding-bottom: 0;
  }

  .notFound__button {
    margin: 184px 0 220px 0;
  }
}

@media (max-width: 615px) {
  .notFound {
    padding-top: 329px;
    padding-bottom: 0;
  }

  .notFound__description {
    margin: 10px 0 0 0;
    font-size: 12px;
  }

  .notFound__button {
    font-size: 12px;
    margin: 283px 0 60px 0;
  }

  .notFound__title {
    font-size: 80px;
  }
}
