.main {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
}

.main__subtitle {
  margin: 0;
  padding: 0;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.87px;
  position: relative;
}

.main__subtitle::after {
  margin: 0;
  padding: 0;
  content: ' ';
  background: black;
  width: 100%;
  height: 1px;
  top: 50px;
  left: 0px;
  position: absolute;
}

@media (max-width: 609px) {
  .main__subtitle {
    letter-spacing: -0.72px;
    font-size: 18px;
  }
}
