@import url(./vendor/normalize.css);
@import url(./vendor/fonts/inter.css);

html {
  scroll-behavior: smooth;
}

.page {
  background-color: #fafafa;
  font-family: 'Inter', 'Arial', sans-serif;
}

.buttons-hover-state:hover {
  cursor: pointer;
  opacity: 0.8;
}

.links-hover-state:hover {
  cursor: pointer;
  opacity: 0.7;
}

ul li { list-style-type: none; }
