.cardList {
  margin: 0;
  padding: 70px 70px 85px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.cardList__items {
  margin: 0;
  padding: 0;
  justify-content: center;
  display: grid;
  list-style: none;
  grid-template-columns: repeat(auto-fit, 270px);
  grid-column-gap: 20px;
  grid-row-gap: 31px;
}

.cardList__items_left {
  justify-content: left;
}

.cardList__buttonMore {
  padding: 0;
  margin: 80px auto 10px;
  width: 320px;
  height: 36px;
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  line-height: normal;
  border-radius: 6px;
  background: #f4f4f4;
  border: none;
  position: relative;
}

.cardList__buttonMore-none {
  opacity: 0;
  visibility: hidden;
}

.cardList__buttonMore:active {
  top: 2px;
}

.cardList_saved {
  padding-bottom: 55px;
}

.cardList__error {
  font-size: 20px;
  text-align: center;
  font-style: normal;
  line-height: 58px;
  font-weight: 400;
}

@media (max-width: 839px) {
  .cardList {
    padding: 70px 30px 79px;
    min-height: calc(100dvh - 661px);
  }

  .cardList_saved {
    padding-bottom: 52px;
  }

  .cardList__items {
    grid-template-columns: repeat(auto-fit, 338px);
    grid-row-gap: 36px;
    grid-column-gap: 30px;
  }
}

@media (max-width: 609px) {
  .cardList {
    padding: 18px 10px 72px;
    min-height: calc(100dvh - 695px);
  }

  .cardList__items {
    grid-template-columns: repeat(auto-fit, 300px);
    grid-row-gap: 20px;
    grid-column-gap: 17px;
  }

  .cardList__error {
    font-size: 15px;
  }
  
  .cardList_saved {
    padding-bottom: 45px;
  }

  .cardList__buttonMore {
    width: 240px;
    height: 36px;
  }
}
